<template>
  <div class="updateTxt">
    <el-input
      ref="sendMsgUpdate"
      :spellcheck="false"
      type="textarea"
      placeholder="请输入内容"
      v-model="chatInputData"
      maxlength="500"
      show-word-limit
      @blur="chatContentChange"
      @input="chatInputFn"
    >
    </el-input>
    <div class="expression">
      <el-popover class="addSome" v-model:visible="expressionFlag" width="300px" trigger="click">
        <div>
          <div class="classStyle">
            <span
              @click="expressionListActive = index"
              v-for="(item, index) in expressionList"
              :key="index"
              :style="{
                background: expressionListActive == index ? '#e7e7e7' : ''
              }"
              >{{ item.name }}</span
            >
          </div>
          <div
            class="emojisList"
            v-for="(item, index) in expressionList"
            :key="index"
            :style="{
              display: expressionListActive == index ? 'block' : 'none'
            }"
          >
            <div>
              <span
                @click="insertEmojis(item1)"
                v-for="(item1, index1) in item.list"
                :key="index1"
                >{{ item1 }}</span
              >
            </div>
          </div>
        </div>
        <template #reference>
          <i class="iconfont icon-biaoqing"></i>
        </template>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { ref, reactive, nextTick, watch, onMounted } from 'vue'
import { inputInsert } from '@/util/common.js'
import { expressionList } from '@/util/emojis.js'
export default {
  props: {
    chatData: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const chatInputData = ref(props.chatData)
    const sendMsgUpdate = ref(null)
    let expressionFlag = ref(false)
    let expressionListActive = ref(0)
    let rangePos = reactive({
      start: 1,
      end: 1
    })
    watch(
      expressionFlag,
      (val) => {
        if (val) {
          nextTick(() => {
            sendMsgUpdate.value.$el.firstElementChild.focus()
            sendMsgUpdate.value.$el.firstElementChild.selectionStart = rangePos.start
            sendMsgUpdate.value.$el.firstElementChild.selectionEnd = rangePos.end
          })
        }
      },
      {}
    )
    // 检测输入框文字变化
    const chatContentChange = () => {
      rangePos.start = sendMsgUpdate.value.$el.firstElementChild.selectionStart
      rangePos.end = sendMsgUpdate.value.$el.firstElementChild.selectionEnd
    }
    const insertEmojis = (item) => {
      let { content, callback } = inputInsert(item, sendMsgUpdate, chatInputData.value, rangePos)
      chatInputData.value = content
      context.emit('getChatData', content)
      nextTick(() => {
        callback()
      })
      expressionFlag.value = false
    }
    const chatInputFn = (val) => {
      context.emit('getChatData', val)
    }
    onMounted(() => {
      sendMsgUpdate.value.$el.firstElementChild.focus()
    })
    return {
      chatInputData,
      sendMsgUpdate,
      expressionFlag,
      expressionListActive,
      chatContentChange,
      chatInputFn,
      insertEmojis,
      expressionList
    }
  }
}
</script>

<style lang="less" scoped>
.updateTxt {
  .el-textarea {
    width: 500px;
    overflow: hidden;
    overflow-y: auto;
    :deep(.el-textarea__inner) {
      padding: 10px 0 10px 20px;
      resize: none;
      height: 110px;
      border-radius: 0;
      &:focus {
        border-color: #dcdfe6 !important;
      }
    }
  }
  .expression {
    padding: 0 10px;
    width: 500px;
    height: 33px;
    border: 1px solid #dcdfe6;
    border-width: 0 1px 1px 1px;
    display: flex;
    align-items: center;
    i {
      font-size: 24px;
      cursor: pointer;
      user-select: none;
    }
  }
}
.emojisList {
  width: 100%;
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
  div {
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
  }
  span {
    display: block;
    width: 49px;
    height: 40px;
    cursor: pointer;
    margin: 0 5px 5px 0;
    font-size: 30px;
    user-select: none;
    text-align: center;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
}
.el-popover .classStyle {
  padding-bottom: 12px;
  span {
    display: inline-block;
    padding: 5px 4px;
    font-size: 12px;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
  }
}
</style>
